import React, { useState } from 'react'
import apiAgent from '../../api/apiAgent';

const ErrorPage = () => {
  const {userTheme } = apiAgent();
  const [themeData, setThemeData] = useState(userTheme.clientInfo());

  return (
    <div>
      <div className="row mt-1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="img_grid d-flex justify-content-center">
            <div className="logo_part">
              <img
                src={themeData.client_logo}
                alt="logo"
                id="logo"
                className="logo_img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-12 col-sm-12 col-md-6">
          <div className="details mt-5">
            <div className="report_headings">
              <h2
                style={{ color: themeData.theme_settings.primary_color, textAlign:"center" }}
              >Opps! Sorry, Something went worng</h2>
            </div>  
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>

  )
}

export default ErrorPage
